<template>
  <el-button
    :type="type"
    :size="size"
    @click="handleChange"
    :loading="loading"
    :class="computedStyleClass"
    :disabled="disabled"
    :plain="plain"
    :title="title"
    :round="round"
  >
    <slot></slot>
  </el-button>
</template>

<script setup lang="ts">
import { computed } from "vue"

interface NiceButtonProps {
  type?: string
  size?: string
  title?: string
  loading?: boolean
  disabled?: boolean
  plain?: boolean
  round?: boolean
  additionalStyling?: string
}

const props = withDefaults(defineProps<NiceButtonProps>(), {
  type: "default",
  size: "default",
  title: "",
  loading: false,
  disabled: false,
  plain: false,
  round: false,
  additionalStyling: "",
})

const emits = defineEmits(["click"])

const handleChange = value => {
  emits("click", value)
}

const computedStyleClass = computed(() => props.additionalStyling || "")
</script>

<style>
.el-button {
  font-size: var(--ps-font-size-sm);
  padding: 8px 15px;
  --el-button-disabled-bg-color: var(--ps-color-primary-light);
  --el-button-disabled-border-color: var(--ps-color-primary-light);
}

.el-button--small {
  font-size: var(--ps-font-size-xxs);
  padding: 5px 12px;
  border-radius: var(--el-border-radius-base);
}
.el-button--large {
  font-size: var(--ps-font-size-lg);
  padding: 12px 19px;
}

.item .el-button,
.formItem .el-button {
  height: 100%;
  border-radius: 0px 3px 3px 0px;
  padding: 0 10px;
}
</style>
