// FIXME: this is not an plugin, it's a service

import axios from "../config/axios"

export type ApiModel =
  | "Broker"
  | "ClientStatus"
  | "CustomFieldGroup"
  | "CustomField"
  | "DealPipeline"
  | "DetailViewField"
  | "DetailViewGroup"
  | "Document"
  | "Folder"
  | "Location"
  | "Shopwindow"
  | "Property"
  | "PropertyReportStat"
  | "PropertyStatus"
  | "Recipe"
  | "MessageCategory"
  | "NoteCategory"
  | "TodoCategory"
  | "EventCategory"
  | "LetterCategory"
  | "DecisionCategory"
  | "Task"
  | "Notification"
  | "DashboardWidget"
  | "CommissionSplit"
  | "LetterTemplateTranslation"
  | "Portal"
  | "TipsterPortalInstance"

async function create<T>(model: ApiModel, attributes: any, responsePayload: string = " { id }"): Promise<T> {
  const response = await axios.post(`/api/v1/graphql`, {
    query: `mutation ($attributes: ${model}Attributes!) {
        create${model}(attributes: $attributes) ${responsePayload}
      }`,
    variables: {
      attributes,
    },
  })

  return handleResponse(response)[`create${model}`]
}

async function update<T>(
  model: ApiModel,
  id: Number,
  attributes: any,
  responsePayload: string = " { id }"
): Promise<T> {
  const response = await axios.post(`/api/v1/graphql`, {
    query: `mutation ($id: Int!, $attributes: ${model}Attributes!) {
        update${model}(id: $id, attributes: $attributes) ${responsePayload}
      }`,
    variables: {
      id,
      attributes,
    },
  })

  return handleResponse(response)[`update${model}`]
}

async function updateShop<T>(attributes: any, responsePayload = "{ id }"): Promise<T> {
  const response = await axios.post(`/api/v1/graphql`, {
    query: `mutation ($attributes: ShopAttributes!) {
        updateShop(attributes: $attributes) ${responsePayload}
      }`,
    variables: {
      attributes,
    },
  })

  return handleResponse(response)[`updateShop`]
}

async function updateBroker<T>(attributes: any): Promise<T> {
  const response = await axios.post(`/api/v1/graphql`, {
    query: `mutation ($attributes: BrokerAttributes!) {
        updateBroker(attributes: $attributes, crmSettings: true) {
          id
        }
      }`,
    variables: {
      attributes,
    },
  })

  return handleResponse(response)[`updateShop`]
}

async function destroy<T>(model: ApiModel, id: number): Promise<T> {
  const response = await axios.post(`/api/v1/graphql`, {
    query: `mutation ($id: Int!) {
        destroy${model}(id: $id) {
          id
        }
      }`,
    variables: {
      id,
    },
  })

  return handleResponse(response)[`destroy${model}`]
}

async function sort<T>(model: ApiModel, items: [number]): Promise<T> {
  const response = await axios.post(`/api/v1/graphql`, {
    query: `mutation ($items: [Int!]!) {
        sort${model}(items: $items) {
          id
        }
      }`,
    variables: {
      items,
    },
  })

  return handleResponse(response)[`sort${model}`]
}

async function mutation<T = any>(action: string, input: any = {}, responseData = "clientMutationId"): Promise<T> {
  const response = await axios.post(`/api/v1/graphql`, {
    query: `mutation ($input: ${_.upperFirst(action)}Input!) {
      ${action}(input: $input) {
        ${responseData}
      }
    }`,
    variables: { input },
  })

  return handleResponse(response)[action]
}

function handleResponse(response: any): any {
  if (response.data.errors) {
    console.table(response.data.errors, ["message"])
    throw { error_messages: response.data.errors.map(e => e.message) }
  }

  return response.data.data
}

export default {
  create,
  update,
  destroy,
  sort,
  updateShop,
  updateBroker,
  mutation,
}
