// utility to transform object style options to array style options for select/radio components

export interface OptionsObject {
  id: string | number | null // this is the value
  [key: string]: any
}

export type OptionsObjectOrArray = OptionsObject[] | Record<string, any>

export function transformOptionsObjectToArray(options: Record<string, any>, labelKey: string): OptionsObject[] {
  return Object.entries(options).map(([key, label]) => ({ id: key, [labelKey]: label }))
}

export function ensureOptionsArray(options: OptionsObjectOrArray | undefined, labelKey: string): OptionsObject[] {
  if (!options) return []
  return Array.isArray(options) ? options.filter(Boolean) : transformOptionsObjectToArray(options, labelKey)
}
