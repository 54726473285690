<template>
  <span class="m-1">
    <nice-tooltip :content="hintHTML" :html-allowed="true">
      <fa-icon :name="icon" />
    </nice-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    hint: {
      type: String,
    },
    icon: {
      type: String,
      default: "question-circle",
    },
  },
  computed: {
    hintHTML() {
      return this.$customFilters.simpleFormat(this.hint)
    },
  },
}
</script>
