<template>
  <textarea ref="input" style="opacity: none; position: absolute; left: -9999px" :value="value"></textarea>
</template>

<script>
export default {
  props: {
    value: String,
  },
  expose: ["execute"],
  methods: {
    execute() {
      this.$refs.input.select()
      document.execCommand("copy")
      App.flashy(this.$t("clipboardCopy"))
    },
  },
}
</script>
