<template>
  <div class="box-row" :class="theme">
    <div class="box-cell">
      <span class="box-rowLabel">
        <slot name="label">{{ label }}</slot>
        <tooltip v-if="copyValue" :content="copyTooltip">
          <a @click="() => this.$refs.clipboardCopy.execute()" class="ml-1">
            <fa-icon name="copy" class="light" />
          </a>
        </tooltip>
        <clipboard-copy v-if="copyValue" ref="clipboardCopy" :value="copyValue" />
        <tip v-if="hint" :hint="hint" icon="info-circle" class="light" />
      </span>
    </div>
    <div
      class="box-cell"
      :class="{
        'items-baseline': field && field.componentName && field.componentName == 'list',
        'custom-color-available': field && field.color,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    field: {
      type: Object,
      required: false,
    },
    hint: {
      type: String,
      required: false,
    },
    theme: {
      type: String,
      required: false,
      default: "default",
    },
    copyTooltip: {
      type: String,
      default: "Copy",
    },
    copyValue: {
      default: null,
    },
  },
}
</script>

<style lang="scss">
.box-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 0.4rem;
}

.box-cell:last-child {
  flex: 2;
}

.box-cell {
  flex: 1;
  align-self: stretch;
  display: flex;
  position: relative;
}

.box-rowLabel {
  padding: 0.3rem 0;
}

.box-cell .dv-input,
.box-cell > input,
.box-cell .el-textarea__inner,
.box-cell > textarea,
.box-cell .input {
  border: none;
  width: 100%;
  height: 100%;
  line-height: initial;
  padding: 3px 6px;
  border-radius: 3px;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset, rgba(15, 15, 15, 0.1) 0px 1px 1px inset;
  min-width: 0; /* firefox bugfix, see https://stackoverflow.com/a/48326796/5718861 */
}

.box-cell .pac-target-input {
  height: 30px;
}

.box-cell .dp__input {
  border: none;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset, rgba(15, 15, 15, 0.1) 0px 1px 1px inset;
}

.box-cell .el-select__wrapper {
  --el-fill-color-blank: #f7f7f5;
}

.box-cell .el-input__inner {
  // padding: 0;
  font-size: 14px;
  height: 30px;
  line-height: normal;
}

.box-cell .el-select .el-tag__close.el-icon-close {
  right: -2px;
  color: #555;
  font-weight: 600;
}

.box-row.default {
  margin-bottom: 0.4rem;
}

.custom-color-available [data-value-missing] .el-input__inner,
.custom-color-available [data-value-missing].el-textarea__inner,
.custom-color-available [data-value-missing].el-input__inner {
  background-color: transparent !important;
}

.default {
  .box-cell .dv-input,
  .box-cell .dp__input,
  .box-cell > input,
  .box-cell .el-textarea__inner,
  .box-cell > textarea,
  .box-cell .el-input__wrapper,
  .box-cell .input {
    background: rgb(247, 247, 245);
  }
}
</style>
