import { ComponentCustomProperties, getCurrentInstance } from "vue"
import api from "./api"
import customAxios from "@/config/axios"

// HINT: this works only with composition-api
const useCore = () => {
  const instance = getCurrentInstance()

  if (!instance) {
    throw new Error("useCore must be called within a composition API component")
  }

  const vm = instance.proxy!

  return {
    api, // this is fine, because it's a singleton
    graphql: vm.$graphql as ComponentCustomProperties["$graphql"], // this is actually bound to the app instance
    axios: customAxios,
    db: vm.$db, // should be the same instance as in the app instance, if imported directly from the db file it will loose reactivity
    root: vm.$root,
  }
}

export default useCore
